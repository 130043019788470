/**
 * True if application is running in production
 */
const isProduction = process.env.NODE_ENV === 'production'

/**
 * True if application is running in staging
 */
const isStaging = process.env.HEROKU_APP_NAME && process.env.HEROKU_APP_NAME.endsWith('stage')

/**
 * True if application is running in review app
 */
const isReviewApp = Boolean(process.env.HEROKU_BRANCH)

/**
 * True if application is running in production but not review or staging app
 */
const isRealProductionEnv = isProduction && !isReviewApp && !isStaging

/**
 * Returns the deployment
 */
function getDeploymentName() {
	if (isReviewApp) {
		return 'reviewapp'
	} else if (isStaging) {
		return 'staging'
	} else if (isProduction) {
		return 'production'
	} else {
		return 'development'
	}
}
const deploymentName = getDeploymentName()

export { isProduction, isReviewApp, isStaging, deploymentName, isRealProductionEnv }
