function initNativeAds(oAds, placeholderNode: HTMLElement) {
	const originalHtml = placeholderNode.innerHTML
	placeholderNode.innerHTML = `<div
			class="o-teaser"
			data-o-ads-name="PartnerContent"
			data-o-ads-targeting="pos=native;format=stacked;appname=homepage"
			data-o-ads-formats="PartnerContent"
			data-o-ads-component="slot"
		></div>`

	const adsNode = placeholderNode.querySelector('[data-o-ads-component]')
	oAds.slots.initSlot(adsNode)
	oAds.utils.on('slotExpand', (event: CustomEvent) => {
		if (event && event.detail) {
			const adsSlot = event.detail
			if (adsSlot.pos === 'native' && adsSlot.isEmpty) {
				placeholderNode.innerHTML = originalHtml
			}
		}
	})
}

export default function displayNativeAds() {
	// The last storyGroup in Top stories
	const lastStoryGroupNode = document.getElementById('native-ads-placeholder')
	if (!lastStoryGroupNode) {
		return
	}

	const oAds = window.oAds
	if (oAds && oAds.isInitialised) {
		initNativeAds(oAds, lastStoryGroupNode)
	} else {
		document.addEventListener('oAds.initialised', (() => {
			initNativeAds(oAds, lastStoryGroupNode)
		}) as EventListener)
	}
}
