import { createCCPALink } from './create-ccpa-link'
import { fetchLegislation, buildConsentPageUrl } from '@financial-times/privacy-legislation-client'

export async function makeUiCcpaCompliant() {
	try {
		// Get a list of the applicable legislationIds for the user's region
		const CONSENT_URL = 'https://www.ft.com/preferences/privacy-ccpa'
		const { legislation } = await fetchLegislation()
		const consentPageUrl = buildConsentPageUrl({ url: CONSENT_URL, legislation })

		// If the user is in California update our UI to meet CCPA requirements
		if (legislation.has('ccpa')) {
			createCCPALink(consentPageUrl)
		}
	} catch (err) {
		console.error(err)
	}
}
