import { ClientFlags, PageProps } from '../types'

/**
 * Returns true if the PG ads tech is enabled
 */
export function isPGAdsEnabled(flags: PageProps['flags'] | ClientFlags) {
	if (!flags) {
		return true
	}

	if (typeof flags.get === 'function') {
		return !!flags.get('adsPGHomePage') || !!flags.get('adsForcePG')
	}

	return !!flags['adsPGHomePage'] || !!flags['adsForcePG']
}
