import type { Edition } from '../../types'

export type Security = {
	name: string
	symbol: string
	type: string
}

type RegionalSecurities = {
	[Property in Edition]: Security[]
}

const regionalSecurities: RegionalSecurities = {
	uk: [
		{
			name: 'FTSE 100',
			symbol: 'FTSE:FSI',
			type: 'indices',
		},
		{
			name: 'S&P 500',
			symbol: 'INX:IOM',
			type: 'indices',
		},
		{
			name: 'Euro/Dollar',
			symbol: 'EURUSD',
			type: 'equities',
		},
		{
			name: 'Pound/Dollar',
			symbol: 'GBPUSD',
			type: 'equities',
		},
		{
			name: 'Brent Crude Oil',
			symbol: 'IB.1:IEU',
			type: 'equities',
		},
		{
			name: '10 Year US Gov',
			symbol: 'JPMUS10YCMY:REU',
			type: 'indices',
		},
	],
	international: [
		{
			name: 'S&P 500',
			symbol: 'INX:IOM',
			type: 'indices',
		},
		{
			name: 'Shanghai',
			symbol: 'SHI:SHH',
			type: 'indices',
		},
		{
			name: 'FTSE 100',
			symbol: 'FTSE:FSI',
			type: 'indices',
		},
		{
			name: 'Euro/Dollar',
			symbol: 'EURUSD',
			type: 'equities',
		},
		{
			name: 'Brent Crude Oil',
			symbol: 'IB.1:IEU',
			type: 'equities',
		},
		{
			name: '10 Year US Gov',
			symbol: 'JPMUS10YCMY:REU',
			type: 'indices',
		},
	],
}

export default regionalSecurities
