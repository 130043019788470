import { h } from '@financial-times/x-engine'
import React from 'react'
import classNames from 'classnames'

export function percentChangeLabel(percentChange: number) {
	if (percentChange === 0) return 'no-change'
	return percentChange < 0 ? 'down' : 'up'
}

export type DataItemProps = {
	index: number
	name: string
	href: string
	percentChange?: number
}

export default function DataItem({ index, name, href, percentChange }: DataItemProps) {
	if (!percentChange) {
		return null
	}
	const priceChangeDirection = percentChangeLabel(percentChange)
	const secondDecimalPercentage = Math.abs(percentChange).toFixed(2)

	return (
		<li className="markets-data__item" data-trackable={`item | ${index + 1}`}>
			<a href={href} className="markets-data__item-link" data-trackable="link">
				<span className="markets-data__item-name">{name}</span>
				<span className={`markets-data__item-change markets-data__item-change--${priceChangeDirection}`}>
					{priceChangeDirection === 'up' && <span>&#43;</span> /* &#43; HTML CODE for plus  */}
					{priceChangeDirection === 'down' && (
						<span>
							<span className="o-normalise-visually-hidden"> Minus </span>
							{/* &#8722; HTML CODE for minus */}
							<span aria-hidden="true">&#8722;</span>
						</span>
					)}
					{secondDecimalPercentage}%
				</span>
			</a>
		</li>
	)
}
