declare global {
	interface Window {
		_sf_async_config?: {
			uid: number
			domain: string
			loadLibrary(): void
			useCanonical?: boolean
			useCanonicalDomain?: boolean
			topStorageDomain?: string
		}
	}
}

// Implements Chartbeat's suggested solution of splitting the loading of
// the scripts into two parts. While the headline testing library is loaded in the head,
// here we load the core library in the body and fetch basic subscription info. Reference:
// https://docs.chartbeat.com/cbp/tracking/standard-websites/alternative-integrations-web#loading-our-tag-in-the-less-than-body-greater-than
export default async function initChartbeat() {
	const { _sf_async_config: chartbeat } = window

	if (!chartbeat) {
		return
	}

	try {
		chartbeat.loadLibrary()
	} catch (e) {
		console.error('Error creating chartbeat script tag')
	}
}
