import React from 'react'
import DataItem, { DataItemProps } from './DataItem'
import { h } from '@financial-times/x-engine'

export type MarketsDataProps = {
	data?: Array<DataItemProps>
}

export default function MarketsData({ data = [] }: MarketsDataProps) {
	return (
		<div className="markets-data-wrapper js-markets-data">
			<div className="o-grid-container">
				<div className="markets-data" data-trackable="header | markets data">
					<ul className="markets-data__items markets-data__items--hidden">
						{data.length
							? data.map((marketData, index) => <DataItem key={marketData.name} index={index} {...marketData} />)
							: null}
					</ul>
					<a href="https://markets.ft.com/data" className="markets-data__item-link" data-trackable="link">
						Visit Markets Data
					</a>
				</div>
			</div>
		</div>
	)
}
