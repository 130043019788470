import type { Edition } from '../../types'

import { render, h } from '@financial-times/x-engine'
import MarketsData from '/packages/components/Molecules/MarketsData'
import regionalSecurities, { Security } from './regionalSecurities'

export const MARKETS_API_ENDPOINT = 'https://markets-data-api-proxy.ft.com/research/webservices/securities/v1/quotes'

function fetchData(symbols: string) {
	const errorMsg = 'There was an error fetching the markets data.'

	// TODO: Implement timeout of 5s
	return fetch(`${MARKETS_API_ENDPOINT}?symbols=${symbols}`)
		.then((response) => {
			if (response.ok) {
				return response.json()
			}
			console.error(errorMsg)
		})
		.catch((e) => {
			console.error(errorMsg, e)
		})
}

function formatToLayoutStructure(data, securities: Security[]) {
	return data
		.filter((marketData) => !marketData.partialError && marketData.quote.change1Day)
		.map(({ quote, symbolInput }) => {
			const securityInfo = securities.find((security) => security.symbol === symbolInput)
			if (!securityInfo) return null
			const { name, type } = securityInfo
			return {
				name,
				percentChange: quote.change1DayPercent,
				href: `https://markets.ft.com/data/${type}/tearsheet/summary?s=${symbolInput}`,
			}
		})
		.filter((item) => item)
}

export const getMarketsData = async (edition: Edition) => {
	const securities = regionalSecurities[edition]
	const symbols = securities.map((security) => security.symbol).join(',')
	const marketsData = await fetchData(symbols)

	if (marketsData) {
		return formatToLayoutStructure(marketsData.data.items, securities)
	}

	return null
}

async function init({ edition = 'uk' }: { edition: Edition }) {
	const data = await getMarketsData(edition)

	if (data) {
		const data = await getMarketsData(edition)

		if (data) {
			render(h(MarketsData, { data }), document.querySelector('.js-markets-data'))
			window.setTimeout(() => {
				document.querySelector('.markets-data__items').classList.remove('markets-data__items--hidden')
			}, 10)
		}
	}
}

export default { init }
